<template>
    <section class="page-intro">
        <div class="page-intro__container">
            <h1 class="page-intro__title" v-html="title" />
            <p class="page-intro__lead" v-html="lead" />
        </div>
    </section>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';

export default defineComponent({
    setup: () => ({
        title: computed(() => translate('page', 'home', 'intro', 'title')),
        lead: computed(() => translate('page', 'home', 'intro', 'lead')),
    }),
});
</script>

<style lang="scss">
.page-intro {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    &:before {
        content: '';
        z-index: -1;
        /*
        @include position-corner;
        position: absolute;
        background: url('/bg.jpg') top no-repeat;
        background-size: 500%;
        @include breakpoint(medium-large) {
            background-size: cover;
        }
        */
        left: 0;
        bottom: 0;
        position: absolute;
        width: 45%;
        padding-bottom: 45%;
        background-image: url('/svg/1.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__container {
        width: 100%;
        @include container;
        flex-flow: column;
        align-items: center;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        @include breakpoint(large) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
    &__title,
    &__lead {
        max-width: 37rem;
        line-height: 1.5;
        // color: #fff;
        font-size: 1.1rem;
        text-align: center;
        @include breakpoint(medium-large) {
            font-size: 1.25rem;
        }
    }
    &__title {
        margin-bottom: 2rem;
    }
    &__lead {
        // font-size: 1rem;
        // @include breakpoint(medium-large) {
        //     font-size: 1.25rem;
        // }
    }
}
</style>
